<template>
  <!-- 我的考试 -->
  <div class="foxRight">
    <div class="dis_flex_between">
      <div class="tabs">
        <div v-for="(item, index) in TabsList" :key="index" class="tab-item" :class="tabActive == index ? 'on' : ''"
          @click="searchClick(index)">
          {{ item.name }}
        </div>
      </div>
    </div>
    <!-- 试卷包 -->
    <div class="list-two">
      <div v-for="(item, index) in myPaperList" :key="index" class="content" @click.stop="getDetail(item)">
        <div class="item">
          <template v-if="item.type == 1">
            <img class="item-img" src="@/assets/img/partner/paperBox.png" alt="">
            <div class="item-content">
              <div class="item-text elp">{{ item.name }}</div>
              <div class="item-tips">
                <div class="tips-text">
                  共{{
                    item.paperNameList ? item.paperNameList.length : 0
                  }}套试卷
                </div>
              </div>
            </div>
            <img v-if="!item.open" style="height: 16px; width: 8px; margin-top: 3px"
              src="@/assets/img/partner/arrow.png" alt="">
            <img v-else style="height: 8px; width: 16px; margin-top: 8px" src="@/assets/img/partner/down.png" alt="">
          </template>
          <template v-else>
            <div class="flexRow">
              <img class="item-image" src="@/assets/img/partner/paper2.png">
              <div class="item-content">
                <div class="item-text ellipsis">{{ item.name }}</div>
                <div class="item-tips">
                  <div class="tips-text">
                    共{{ item.subjectNum ? item.subjectNum : 0 }}题
                  </div>
                  <div v-if="search.IsPurchase == 1 && item.className" class="m-r-4 m-l-4">
                    /
                  </div>
                  <div v-if="search.IsPurchase == 1 && item.className" class="tips-text">
                    {{ item.className }}
                  </div>
                </div>
              </div>
              <!-- <div class="examine" >查看解析</div> -->
              <div class="flex_Box">
                <!-- <div style="margin-right: 10px" class="keep-learn viewTopic">查看解析</div>
                <div class="keep-learn" @click="goLearn(item)">重新答题</div> -->
                <!-- <div v-if="item.problemState == 3" class="examine">查看解析</div> -->
                <el-button v-if="item.problemState == 3" class="btn1" @click.stop="goLearn(item)">重新答题</el-button>
                <el-button class="btn3" @click.stop="goLearn(item)" v-if="item.problemState === 1">开始答题</el-button>
                <el-button v-if="item.problemState === 2" class="btn3 btn2" @click.stop="goLearn(item)">继续答题</el-button>

              </div>
            </div>
          </template>
        </div>
        <div v-for="(items, indexs) in item.paperNameList" v-show="item.open" :key="indexs" class="paperNameList">
          <div class="imgBox">
            <img src="@/assets/img/partner/paper2.png" alt="">
          </div>
          <div class="">
            <div class="item-text ellipsis">{{ items.name }}</div>
            <div class="paperName-text" style="">
              共{{ items.subjectNum ? items.subjectNum : 0 }}题
            </div>
          </div>
          <!-- <div @click.stop="goFindAnalysis(item)" class="examine" v-if="items.problemState === 3">查看解析</div> -->
          <el-button class="btn" @click.stop="goLearns(items, item)" v-if="items.problemState === 1">开始答题</el-button>
          <el-button v-else-if="items.problemState === 2" class="btn btn2"
            @click.stop="continues(items, item)">继续答题</el-button>
          <el-button v-else-if="items.problemState === 3" class="btn1"
            @click.stop="goLearns(items, item)">重新答题</el-button>
        </div>
      </div>
    </div>
    <div v-if="!myPaperList || myPaperList.length == 0" class="nothing">
      <div class="nothing-text">暂无内容哦~</div>
    </div>
    <el-pagination class="pagination partner-pagination" :total="total" :current-page="search.pageNum"
      :page-sizes="[8, 16, 25, 40]" :page-size="search.pageSize" :pager-count="5" background
      layout="total, sizes, prev, pager, next" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
  </div>
</template>
<script>
import TypeChooseView from '@/components/typeChooseView.vue'
import Bus from '@/api/bus'
import Vue from 'vue'
import { getInfo } from '@/api/cookies'
import { AnswerClient } from '@/api/task/answerClient'
import {
  getQuestionStudentCourseList,
  getFallibleStudentCourseList,
  getStudentCourseList
} from '@/api/question'
import ExamPlace from '@/views/quesTion/examPlace/examPlace.vue'
import questionItem from '@/components/Know/classCom/question-item.vue'
const answerClient = new AnswerClient()
export default {
  components: { TypeChooseView, ExamPlace, questionItem },
  data() {
    return {
      myPaperList: [], // 试卷列表
      getInfo: null,
      wrongTopic: false,
      tabActive: 0,
      questionName: [
        { name: '客观题题库' },
        { name: '易错错题库' },
        { name: '个人错题库' }
      ],
      // , { name: '错题库' }
      TabsList: [{ name: '套餐赠送' }, { name: '单独购买' }],
      activeNames: [0],
      questionList: [],
      fallibleQuestionList: [],
      myWrongQuestionList: [],
      search: {
        pageSize: 8,
        pageNum: 1,
        IsPurchase: 1 // 1赠送，0购买
        // search: "", // 关键字
      },
      total: 0,
      other: [
        // { fId: 4001, fName: "虚拟考场" },
        { fId: 4002, fName: '考点练习' },
        { fId: 4003, fName: '每日一练', path: '/all/examPlace/answer?type=2' }
      ],
      /* 考点讲解显示 */
      ifPlace: false,
      thisList: null,
      isContinue: true
    }
  },
  async created() {
    this.userInfo = getInfo()
    await this.getQuestionList()
    this.getMyPaperList()
  },
  methods: {
    submitShiJuan(item) {
      this.$router.replace({
        path: `/quesTion/examDetails?title=${item.title}&paperId=${this.paperId}&IsPurchase=true&type=12&examType=${this.examType}`
      })
    },
    goOrder(item) {
      if (!this.userInfo) {
        this.$notify({
          title: '错误',
          message: '未登录，无法做题, 请先去登录',
          type: 'error',
          duration: 2000
        })
        return
      }
      const sub = {
        packId: item.id,
        classId: item.classId
      }
      const data = Vue.prototype.export(sub)
      this.$router.push(`/all/examPack?${data}`)
    },
    // 获取试卷包和试卷整合列表
    async getMyPaperList() {
      const loading = this.$loading({
        lock: true,
        text: '加载中',
        spinner: '',
        background: 'rgba(0, 0, 0, 0.2)'
      })
      const { rows, total } = await answerClient.getPcMyPackageAndPaper(
        this.search
      )
      rows.forEach((e) => {
        e.open = false
      })
      this.myPaperList = rows
      console.log("========123============", this.myPaperList);
      this.total = total
      loading.close()
    },
    // 获取错题库整合列表
    async getMistakesPaperList() {
      const loading = this.$loading({
        lock: true,
        text: '加载中',
        spinner: '',
        background: 'rgba(0, 0, 0, 0.2)'
      })
      const { rows, total } = await answerClient.getMistakesPaperList(
        this.search.pageSize,
        this.search.pageNum
      )
      this.myPaperList = rows
      console.log("========456============", this.myPaperList);
      this.total = total
      loading.close()
    },
    // 切换试卷
    searchClick(val) {
      this.tabActive = val
      this.search.pageNum = 1
      if (val === 1) {
        this.search.IsPurchase = 0
      } else if (val === 0) {
        this.search.IsPurchase = 1
      }
      this.myPaperList = []
      this.total = 0
      this.getMyPaperList()
    },
    handleSizeChange(val) {
      this.search.pageSize = val
      this.getMyPaperList()
    },
    handleCurrentChange(val) {
      this.search.pageNum = val
      this.getMyPaperList()
    },
    /* 试卷b做题 */
    goPage(item) {
      if (!this.userInfo) {
        this.$notify({
          title: '错误',
          message: '未登录，无法做题, 请先去登录',
          type: 'error',
          duration: 2000
        })
        return
      }
      this.$router.push(`/all/examPack?packId=${item.id}`)
    },
    getDetail(item) {
      item.open = !item.open
      answerClient
        .getPackageDetails(
          item.id,
          this.userInfo ? this.userInfo.id : undefined,
          item.classId ? item.classId : undefined
        )
        .then((res) => {
          item.detail = res.data.paper
          this.$set(item, 'detail', res.data.paper)
          console.log(item, 'this.detail')
          item.detail.entrantsPrice =
            res.data?.product?.entrantsPrice ||
              res.data?.product?.entrantsPrice == 0
              ? res.data.product.entrantsPrice
              : res.data.paper.originalPrice
          item.detail.SubjectNum = res.data.paper.paperNameList
            ? res.data.paper.paperNameList.length
            : 0
          const paperNameList = res.data.paper.paperNameList.map((item) => {
            this.$set(item, 'loading', false)
            return item
          })
          // this.$set(item, "paperNameList", paperNameList);
          if (this.IsPurchase) {
            item.detail.status = 1
          } else {
            item.IsPurchase = item.detail.status == 1
          }
        })
      this.$forceUpdate()
    },
    /* 试卷做题 */
    goLearn(item) {
      if (!this.userInfo) {
        this.$notify({
          title: '错误',
          message: '未登录，无法做题, 请先去登录',
          type: 'error',
          duration: 2000
        })
        return
      }
      const sub = {
        id: item.id,
        classId: item.classId,
        title: item.name,
        type: 12,
        IsPurchase: true,
        paperId: item.id,
        examType: 4
      }
      if (this.wrongTopic) {
        sub.wrongTopic = true
      }
      const data = Vue.prototype.export(sub)
      data
      this.$router.push(`/quesTion/examDetails?${data}`)
    },
    // 开始答题
    goLearns(items, item) {
      // this.isContinue = false
      items.loading = true
      answerClient.selectPaperSubjectList(items.id, this.userInfo ? this.userInfo.id : undefined,
        items.classId ? items.classId : undefined, undefined, 4).then((res) => {
          if (res.code == 0) {
            Vue.prototype.goExamPartner(
              items.name,
              items.id,
              4,
              6,
              item.IsPurchase,
              res.data.paper.seeNum,
              item.id,
              undefined,
              item.classId ? item.classId : undefined
            )
          }
          items.loading = false
        })
    },
    // 继续答题
    continues(items, item) {
      items.loading = true
      answerClient.selectPaperSubjectList(items.id).then((res) => {
        if (res.code == 0) {
          Vue.prototype.goExamPartner(
            items.name,
            items.id,
            4,
            6,
            item.IsPurchase,
            res.data.paper.seeNum,
            item.id,
            undefined,
            item.classId ? item.classId : undefined
          )
        }
        items.loading = false
      })
    },
    goFindAnalysis(item) {
      const sub = {
        id: item.id,
        classId: item.classId,
        title: item.name,
        type: 12,
        IsPurchase: true,
        paperId: item.id,
        examType: 4
      }
      if (this.wrongTopic) {
        sub.wrongTopic = true
      }
      const data = Vue.prototype.export(sub)
      this.$router.push(`/partnerPage/report?${data}`)

    },
    /* 进入考点*/
    goPlace(item) {
      if (!this.userInfo) {
        Vue.prototype.goLoginView
        return
      }
      this.ifPlace = true
      this.thisList = item
    },
    // 专属题库/试卷筛选
    // changeTabs(val) {
    //   this.tabActive = val
    //   // 专属题库
    //   if (val == 0) {
    //     this.wrongTopic = false
    //     this.getQuestionList()
    //   } else if (val == 2) {
    //     // 错题库
    //     this.wrongTopic = true
    //     this.search.IsPurchase = 1
    //     this.myPaperList = []
    //     this.total = 0
    //     this.getMistakesPaperList()
    //   } else {
    //     // 试卷
    //     this.wrongTopic = false
    //     this.search.IsPurchase = 1
    //     this.myPaperList = []
    //     this.total = 0
    //     this.getMyPaperList()
    //   }
    // },
    // 获取专属题库三个题型
    async getQuestionList() {
      const loading = this.$loading({
        lock: true,
        text: '加载中',
        spinner: '',
        background: 'rgba(0, 0, 0, 0.2)'
      })
      // 客观题题库
      // const { data } = await getQuestionStudentCourseList()
      // 易错错题库
      // const { data: data2 } = await getFallibleStudentCourseList()
      // 个人错题库
      // const { data: data3 } = await getStudentCourseList()
      // this.questionList = data || []
      // this.fallibleQuestionList = data2 || []
      // this.myWrongQuestionList = data3 || []
      loading.close()
    },
    goToRecord() {
      this.$router.push({
        path: `/all/examinationRecord`
      })
    }
  }
}
</script>
<style lang="less" scoped>
.foxRight {
  background: #ffffff;
  padding-bottom: 20px;

  .dis_flex_between {
    padding: 24px 32px 0 0;
    background: #fff;
    height: 50px;
    align-items: center;

    .tabs {
      display: flex;
      align-items: center;
      color: #666666;
      font-size: 14px;
      // width: 272px;
      height: 44px;
      background: #f5f7f9;
      border-radius: 6px 6px 6px 6px;

      .tab-item {
        cursor: pointer;
        text-align: center;
        width: 100px;
        height: 44px;
        line-height: 44px;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }

      .on {
        width: 100px;
        height: 38px;
        background: #ffffff;
        border-radius: 6px 6px 6px 6px;
        line-height: 38px;
        margin: 4px;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }

    .records {
      width: 84px;
      height: 26px;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1px solid #4a6af0;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #4a6af0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    /deep/ .el-radio-group {
      .el-radio {
        margin-right: 20px;
      }

      .el-cascader-node>.el-radio,
      .el-radio:last-child {
        margin-right: 0;
      }

      .el-radio__label {
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #5a5d5a;
      }
    }
  }

  /deep/.redRadio {
    .is-checked {
      .el-radio__inner {
        border-color: #4a6af0;
        background: #4a6af0;
      }
    }
  }
}

/* 试卷列表 */
.list-two {
  background: #fff;
  margin-top: 15px;
  padding: 0;

  .paperNameList {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 73px;
    margin-left: 70px;
    width: 934px;
    border-bottom: 1px dashed #eee;

    .imgBox {
      width: 34px;
      height: 37px;
      margin-right: 5px;

      img {
        width: 100%;
      }
    }

    .btn {
      position: absolute;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 108px;
      height: 36px;
      border-radius: 25px 25px 25px 25px;
      opacity: 1;
      border: 1px solid #2586f5;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #2586f5;

      &:hover {
        border: 0;
      }
    }

    .btn2 {
      width: 108px;
      height: 36px;
      background: linear-gradient(360deg, #0270E8 0%, #21B4F5 100%);
      opacity: 1;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 25px 25px 25px 25px;

    }

    .examine {
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #717680;
      position: absolute;
      right: 157px;
    }

    .btn1 {
      width: 108px;
      height: 36px;
      border-radius: 18px 18px 18px 18px;
      opacity: 1;
      border: 1px solid #717680;
      position: absolute;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #717680;
    }

    .btn:hover {
      width: 108px;
      height: 36px;
      background: linear-gradient(360deg, #0270E8 0%, #21B4F5 100%);
      opacity: 1;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .paperNameList:nth-last-child(1) {
    border: none;
  }

  .item {
    cursor: pointer;
    width: 1040px;
    height: 70px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.06);
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    padding: 15px 21px;
    margin-bottom: 10px;

    .item-img {
      width: 48px;
      height: 41px;
      flex-shrink: 0;
      margin-right: 12px;
    }

    .item-content {
      width: 100%;
      // height: 56px;
    }
  }
}

.item-content {
  width: 100%;
  height: 100%;

  .item-text {
    max-width: 570px;
    height: 16px !important;
    font-size: 14px !important;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400 !important;
    color: #333333 !important;
    line-height: 16px !important;
  }

  .item-tips {
    margin-top: 12px;
    display: flex;
    align-items: center;
    height: 12px;
    font-size: 10px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 12px;

    .tips-text {
      height: 12px;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      line-height: 12px;
    }
  }
}

.flexRow {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .item-image {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    margin-right: 12px;
  }

  .item-content {
    height: 40px;
  }
}

.flex_Box {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  position: relative;

  .examine {
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #717680;
    position: absolute;
    right: 157px;
  }

  .btn1 {
    width: 108px;
    height: 36px;
    border-radius: 18px 18px 18px 18px;
    opacity: 1;
    border: 1px solid #717680;
    position: absolute;
    // right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #717680;
  }
}

/deep/ .el-collapse-item {
  width: 100% !important;
  height: auto !important;
}

/deep/ .el-tabs {
  width: 100% !important;
  height: auto !important;
}

.classes-question {
  padding: 24px 32px 31px 0;
  background: #FFFFFF;

  /deep/ .el-collapse {
    /deep/ .el-collapse-item {
      width: 1018px !important;
      height: auto !important;
    }

    border: none;

    .el-collapse-item__header {
      width: 100% !important;
      border: none;
    }

    .el-collapse-item__wrap {
      border-bottom: #f5f5f5 1px solid;
    }

    .el-icon-arrow-right {
      transform: rotate(90deg);
    }

    .is-active.el-icon-arrow-right {
      width: auto;
      height: auto;
      transform: rotate(270deg);
      background-color: transparent;
      // text-align: right;
    }
  }

  .title {
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    display: flex;
    align-items: center;

    .line {
      width: 3px;
      height: 16px;
      background: #4a6af0;
      border-radius: 2px 2px 2px 2px;
      margin-right: 9px;
      display: inline-block;
    }
  }
}

.paperName-text {
  margin-top: 8px;
  margin-left: 8px;
  display: flex;
  align-items: center;
  height: 12px;
  font-size: 12px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  line-height: 12px;
}

.btn3 {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 108px;
  height: 36px;
  border-radius: 25px 25px 25px 25px;
  opacity: 1;
  border: 1px solid #2586f5;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #2586f5;

}

.btn2 {
  width: 108px;
  height: 36px;
  background: linear-gradient(360deg, #0270E8 0%, #21B4F5 100%);
  opacity: 1;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px 25px 25px 25px;

}
</style>
